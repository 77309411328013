import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare, faX } from '@fortawesome/free-solid-svg-icons'


function AddFriendsModal({setOpenAddFriendsModal}) {
  return (
    <div className=' h-full bg-color flex flex-col'>
        <div className=' flex justify-between p-2'>
            <p className=' text-2xl'>Пригласи друга — получи бонус!</p>
            <FontAwesomeIcon icon={faX} onClick={() => setOpenAddFriendsModal(false)}/>
        </div>
        <div className=' flex gap-2 m-4'>
            <h2 className=' text-3xl'>0 / 10</h2>
            <div className=' flex text-xl items-end'>
                <p>приглашено</p>
            </div>
        </div>
        <div>
            <p className=' p-2 text-lg text-justify'>Получи <b>2000</b> токенов, когда твой друг запустит бота
                 по твоей ссылке. И каждый раз, когда он пополнит баланс,
                ты получишь дополнительно <b>7% токенов от его пополнения</b>.
            </p>
            <div className=' flex justify-center flex-col items-center w-full p-2'>
                <div className=' rounded-full bg-orange-400 h-[60px] w-[60px] flex justify-center items-center'>
                    <FontAwesomeIcon icon={faShare}/>
                </div>
                <b className='p-2 text-2xl'>Пригласить друзей</b>
            </div>
        </div>
    </div>
  )
}

export default AddFriendsModal