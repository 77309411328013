import React from 'react'
import AllHomeButtons from './components/all-home-buttons'

function Home() {
  return (
    <>
        <AllHomeButtons/>
    </>
  )
}

export default Home