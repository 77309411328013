import Home from "./Home";

function App() {
  return (
    <div className="App bg-color all-max-width text-white h-[100vh] mx-auto">
      <Home/>
    </div>
  );
}

export default App;
