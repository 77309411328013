export const dateAndTokens = [
    {
        date : '08.04.2024 20:21',
        token : '+100,000'
    },
    {
        date : '08.04.2024 20:21',
        token : '+100,000'
    },
    {
        date : '08.04.2024 20:21',
        token : '+100,000'
    },
    {
        date : '08.04.2024 20:21',
        token : '+100,000'
    },
    {
        date : '08.04.2024 20:21',
        token : '+100,000'
    },
]