export const versions = [
    {
        name : 'Haiku',
        description : 'Claude 3 Haiku – самая быстрая и выгодная модель. Обеспечивает мгновенный отклик, становясь вашим AI-ассистентом для решения повседневных задач. Отличается минимальным потреблением токенов. Рекомендуем для ежедневного использования.',
        um : '60%',
        skorost : "100%",
        niskaya : '25%',
    },
    {
        name : 'Sonnet',
        description : 'Claude 3 Sonnet – баланс между интеллектом и скоростью работы, особенно для задач с большим объемом данных. Sonnet рекомендуется для точного понимания и генерации текста, а также для эффективной обработки большого количества данных',
        um : '75%',
        skorost : '75%',
        niskaya : '35%',
    },
    {
        name : 'Opus',
        description : 'Claude 3 Opus – самая мощная и интеллектуальная модель в линейке Claude. Она обеспечивает непревзойденную производительность при решении сложных задач, требующих глубокого понимания и анализа. При этом Opus потребляет значительно больше токенов.',
        um : '100%',
        skorost : '50%',
        niskaya : '100%',
    },
]