import React, { useState } from 'react'
import AddBalanceModal from './add-balance-modal'
import Balance from './balance'
import SelectVersionModal from './select-version-modal'
import AddFriendsModal from './add-friends-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPlus } from '@fortawesome/free-solid-svg-icons';
import QueryHistoryModal from './query-history-modal';
function AllHomeButtons() {
    const [openAddBalanceModal,setOpenAddBalanceModal] = useState(false);
    const [openSelectVersionModal,setOpenSelectVersionModal] = useState(false);
    const [openAddFriendsModal,setOpenAddFriendsModal] = useState(false);
    const [openQueryHistoryModal,setOpenQueryHistoryModal] = useState(false);

  return (
    <div className={`${!openAddBalanceModal ? ' overflow-y-hidden' : 'overflow-y-auto'} relative h-full`}>
        <Balance/>
        <div className=' flex justify-around mt-5 mb-10'>
            <div className=' flex items-center flex-col w-full'>
                <button onClick={() => setOpenAddBalanceModal(true)}><p><FontAwesomeIcon icon={faPlus}/></p>Пополнить баланс</button>
                
                <div className={`h-full w-full all-max-width duration-300 ${openAddBalanceModal ? 'absolute left-0 top-0' : 'absolute left-0 top-[1000px] h-0'}  `}>
                    <AddBalanceModal setOpenAddBalanceModal={setOpenAddBalanceModal} openAddBalanceModal={openAddBalanceModal}/>
                </div>
           </div>
            <div className=' flex items-center flex-col w-full'> 
                <button onClick={() => setOpenAddFriendsModal(true)}><p><FontAwesomeIcon icon={faPlus}/></p>Пригласить друзей и получить бесплатно</button>
                {!openAddBalanceModal ? <div className={`h-full w-full all-max-width duration-300 ${openAddFriendsModal ? 'absolute left-0 top-0' : 'absolute left-0 top-[1000px] h-0'}  `}>
                    <AddFriendsModal setOpenAddFriendsModal={setOpenAddFriendsModal} openAddFriendsModal={openAddFriendsModal}/>
                </div> : ''}
            </div>
        </div>
        <div>
            <div className=''>
                <div className=' flex flex-col p-2 gap-2'>
                    <button onClick={() => setOpenSelectVersionModal(true)} className=' button-color p-3 rounded-lg text-lg'><span><FontAwesomeIcon icon={faCog}/></span> Версия Claude</button>
                    {!openAddBalanceModal ? <div className={`h-full w-full all-max-width duration-300 ${openSelectVersionModal ? 'absolute left-0 top-0' : 'absolute left-0 top-[1000px]'}  `}>
                        <SelectVersionModal setOpenSelectVersionModal={setOpenSelectVersionModal}/>
                    </div> : ''}
                    <button className=' button-color p-3 rounded-lg text-lg'><span><FontAwesomeIcon icon={faCog}/></span> Системный промпт</button>
                    <div className=' flex justify-between gap-2 text-lg'>
                        <button onClick={() => setOpenQueryHistoryModal(true)} className=' button-color p-1 w-full rounded-lg'><span><FontAwesomeIcon icon={faCog}/></span> История запросов</button>
                        {!openAddBalanceModal ? <div className={`h-full w-full all-max-width duration-300 ${openQueryHistoryModal ? 'absolute left-0 top-0' : 'absolute left-0 top-[1000px] h-0'}  `}>
                            <QueryHistoryModal setOpenQueryHistoryModal={setOpenQueryHistoryModal} openQueryHistoryModal={openQueryHistoryModal}/>
                        </div> : ''}
                        <button className=' button-color p-3 w-full rounded-lg'><span><FontAwesomeIcon icon={faCog}/></span> Поддержка</button>
                    </div>
                </div>
            </div>
        </div>
    </div>  
  )
}

export default AllHomeButtons