import React from 'react'

function Balance() {
  return (
    <div className='p-2 text-2xl'>
        <h1>Баланс:</h1>
        <p>12403210 токенов</p>
    </div>
  )
}

export default Balance