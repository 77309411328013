import React, { useState } from 'react'
import { tokenElements } from '../token-elements'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

function AddBalanceModal({setOpenAddBalanceModal,openAddBalanceModal}) {
    const [selectToken,setSelectToken] = useState(null);
    const handleSelectToken = (id) => {
        setSelectToken(id)
    }

  return (
    <>
    <div className='relative bg-color w-full flex flex-col'>
        <div>
            <div>
                <div className=' flex justify-between'>
                    <h2 className=' text-2xl p-4'>Купить токены</h2>
                    <button className=' p-4' onClick={() => {
                        setOpenAddBalanceModal(false);
                        setSelectToken(null);
                    }}> <FontAwesomeIcon icon={faX}/></button>
                </div>
                <p className=' p-4'>Вы платите только за то, что используете. Токены не сгорают.</p>
            </div>
            <div>
                <div className='flex flex-col m-2 p-2 mb-20'>
                    {tokenElements.map(el => <div onClick={() => handleSelectToken(el.id)} 
                    className={`${+selectToken === +el.id && 'border-2 rounded-lg'} cursor-pointer flex justify-between p-2 m-2`}>
                        <div>
                            <div className=' flex items-end'>
                                <h2 className='text-xl'>{el.tokens}</h2>
                                <p className=' ml-2'>токенов:</p>
                            </div>
                            <p className=' opacity-35'>{el.description}</p>
                        </div>
                        <div className=' p-2 flex items-center'>
                            <p>{!el.oldPrice ? <p className=' text-xl'>{el.price}</p> : <div>
                                <p className=' text-xl'>{el.price}</p>
                                <p className=' line-through opacity-35'>{el.oldPrice}</p>
                            </div>}</p>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    </div>
    <div className={`${openAddBalanceModal && 'all-max-width w-full fixed bottom-0'}`}>
        <button className=' w-full p-5 button-color'>Купить</button>
    </div>
    </>
  )
}

export default AddBalanceModal