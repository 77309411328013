import { faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { dateAndTokens } from '../date-and-tokens'

function QueryHistoryModal({setOpenQueryHistoryModal}) {
    const [showAllTokens,setShowAllTokens] = useState(false); 
    const [hideTokens,setHideTokens] = useState(true); 



  return (
    <div className=' bg-color h-full'>
        <div className=' flex justify-between p-2'>
            <h2 className=' text-xl'>История запросов</h2> 
            <button onClick={() => setOpenQueryHistoryModal(false)}>
                <FontAwesomeIcon icon={faX}/>
            </button>
        </div>
        <div className=' mt-4 p-2'>
            <p className=' text-justify'>Что-то типа листа списаний токенов за каждый запрос. сохраняем истории списаний токенов <b>(за 7 дней)</b> и пополнений токенов <b>(за 30 дней)</b></p>
            <div className=' mt-2'>
                <p className=' font-semibold'>Купленные токены</p>
                {hideTokens && !showAllTokens && dateAndTokens.slice(0,3).map(el => <div>
                    <p className=' p-2'>
                            Дата: {el.date} <span className=' text-green-400'>{el.token} токенов</span>
                    </p>
                </div>)}
                {hideTokens && !showAllTokens && <button
                onClick={() => {
                    setShowAllTokens(!showAllTokens);
                    setHideTokens(!hideTokens);
                }
                }>show</button>}
                {!hideTokens && showAllTokens && dateAndTokens.map(el => <div>
                    <p className=' p-2'>
                            Дата: {el.date} <span className=' text-green-400'>{el.token} токенов</span>
                    </p>
                </div>)}
                {!hideTokens && showAllTokens && <button
                onClick={() => {
                    setShowAllTokens(!showAllTokens);
                    setHideTokens(!hideTokens);
                }
                }>hide</button>}
            </div>
            <div className=' mt-4'>
                <p>Токены по ссылкам</p>
                <p className=' p-2'>
                    Дата: 08.04.2024 19:53 <span className=' text-green-400'>+2000 токенов</span>
                </p>
            </div>
            <div className=' mt-4'>
                <p>Потраченные токены</p>
                <p className=' p-2'>
                    Дата: 08.04.2024 19:53 <span className=' text-red-400'>-117 токенов</span>
                </p>
            </div>
        </div>
    </div>
  )
}

export default QueryHistoryModal