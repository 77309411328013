import React, { useState } from 'react';
import { versions } from '../versions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

function SelectVersionModal({ setOpenSelectVersionModal }) {
  const [selectVersion, setSelectVersion] = useState(0);

  return (
    <div className='bg-color h-full flex flex-col '>
      <div>
      <div className='flex justify-between p-2'>
        <h2 className=' text-xl'>Версии Claude</h2>
        <button
            onClick={() => {
                setOpenSelectVersionModal(false);
                setSelectVersion(0);
            }}><FontAwesomeIcon icon={faX}/></button>
      </div>
        <div className=' h-[170px] text-justify'>
          <p className='p-2'>{versions[selectVersion].description}</p>
        </div>
      </div>
      <div>
        <div className='pb-2'>
          <div className='p-2'>
            <p>Ум</p>
            <div className='rounded-lg bg-white w-full h-3'>
              <div style={{ width: versions[selectVersion].um }} className='duration-300 bg-[#5288C1] h-full rounded-lg' />
            </div>
          </div>
          <div className='p-2'>
            <p>Скорость</p>
            <div className='rounded-lg bg-white w-full h-3'>
              <div style={{ width: versions[selectVersion].skorost}} className=' duration-300 bg-[#5288C1] h-full rounded-lg' />
            </div>
          </div>
          <div className='p-2'>
            <p>Низкая Стоимость</p>
            <div className='rounded-lg bg-white w-full h-3'>
              <div style={{ width: versions[selectVersion].niskaya }} className='duration-300 bg-[#5288C1] h-full rounded-lg' />
            </div>
          </div>
        </div>
        <div className=' flex'>
        {versions.map((el, index) => (
          <div key={index} onClick={() => setSelectVersion(index)} className='flex w-full m-2'>
            <button className={` ${selectVersion === index && 'bg-[#5288C1]'} border-2 p-2 w-full rounded-lg font-semibold`}>
              {el.name}
            </button>
          </div>
        ))}
        </div>
      </div>
    </div>
  );
}

export default SelectVersionModal;
