export const tokenElements = [
    {
        tokens : '20,000',
        price : '199₽',
        description : 'Первый шаг к открытию возможностей Claude',
        id : (Math.random() * Date.now()).toFixed(0)
    },
    {
        tokens : '50,000',
        price : '450₽',
        oldPrice : '497₽',
        description : 'Для решения повседневных задач',
        id : (Math.random() * Date.now()).toFixed(0)
    },
    {
        tokens : '100,000',
        price : '845₽',
        oldPrice : '995₽',
        description : 'Базовый пакет для углубленной работы и создания контента',
        id : (Math.random() * Date.now()).toFixed(0)
    },
    {
        tokens : '500,000',
        price : '3990₽',
        oldPrice : '4,995₽',
        description : 'Для интенсивного использования всех функций',
        id : (Math.random() * Date.now()).toFixed(0)
    },
    {
        tokens : '1,000,000',
        price : '7,750₽',
        oldPrice : '9,950₽',
        description : 'Личный AI-ассистент',
        id : (Math.random() * Date.now()).toFixed(0)
    },
]
